import {AttachMoney, Business, BusinessCenter, Diversity2, MonetizationOn, MoneyOff, School, WorkHistory} from '@mui/icons-material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------




const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  student: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard')
};

const navConfigSelf = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu',
    items: [
      // { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'Dashboard', path: PATH_DASHBOARD.dashhome.root, icon: ICONS.dashboard },

         {
        title: 'my registration',
        path: PATH_DASHBOARD.user.registration,
        icon: ICONS.student,
      },

      {
        title: 'charges',
        path: PATH_DASHBOARD.transactions.charges,
        icon: ICONS.invoice,
 
      },
      {
        title: 'transactions & payments',
        path: PATH_DASHBOARD.transactions.payments,
        icon: <MonetizationOn/>,
      
      },{
        title: 'my documents',
        path: PATH_DASHBOARD.document.root,
        icon: ICONS.folder,
      
      }
    ],
  },

];

export default navConfigSelf;
