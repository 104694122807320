import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  // GeneralAppPage,
  // GeneralFilePage,
  // GeneralBankingPage,
  // GeneralBookingPage,
  // GeneralEcommercePage,
  // GeneralAnalyticsPage,
  // Dashboard: User
  // UserListPage,
  // UserEditPage,
  // UserCardsPage,
  // UserCreatePage,
  // UserProfilePage,
  UserAccountPage,
  // Dashboard: Student
  StudentListPage,
  // Dashboard: Ecommerce
  // EcommerceShopPage,
  // EcommerceCheckoutPage,
  // EcommerceProductListPage,
  // EcommerceProductEditPage,
  // EcommerceProductCreatePage,
  // EcommerceProductDetailsPage,
  // Dashboard: Invoice
  // InvoiceListPage,
  // InvoiceDetailsPage,
  // InvoiceCreatePage,
  // InvoiceEditPage,
  // Dashboard: Blog
  // BlogPostsPage,
  // BlogPostPage,
  // BlogNewPostPage,
  // Dashboard: FileManager
  // FileManagerPage,
  // Dashboard: App
  // ChatPage,
  // MailPage,
  // CalendarPage,
  // KanbanPage,
  //
  // BlankPage,
  PermissionDeniedPage,
  Page500,
  Page403,
  Page404,
  // HomePage,
  // FaqsPage,
  // AboutPage,
  // Contact,
  // PricingPage,
  // PaymentPage,
  // ComingSoonPage,
  // MaintenancePage,
  //
  // ComponentsOverviewPage,
  // FoundationColorsPage,
  // FoundationTypographyPage,
  // FoundationShadowsPage,
  // FoundationGridPage,
  // FoundationIconsPage,
  //
  // MUIAccordionPage,
  // MUIAlertPage,
  // MUIAutocompletePage,
  // MUIAvatarPage,
  // MUIBadgePage,
  // MUIBreadcrumbsPage,
  // MUIButtonsPage,
  // MUICheckboxPage,
  // MUIChipPage,
  // MUIDataGridPage,
  // MUIDialogPage,
  // MUIListPage,
  // MUIMenuPage,
  // MUIPaginationPage,
  // MUIPickersPage,
  // MUIPopoverPage,
  // MUIProgressPage,
  // MUIRadioButtonsPage,
  // MUIRatingPage,
  // MUISliderPage,
  // MUIStepperPage,
  // MUISwitchPage,
  // MUITablePage,
  // MUITabsPage,
  // MUITextFieldPage,
  // MUITimelinePage,
  // MUITooltipPage,
  // MUITransferListPage,
  // MUITreesViewPage,
  //
  // DemoAnimatePage,
  // DemoCarouselsPage,
  // DemoChartsPage,
  // DemoCopyToClipboardPage,
  // DemoEditorPage,
  // DemoFormValidationPage,
  // DemoImagePage,
  // DemoLabelPage,
  // DemoLightboxPage,
  // DemoMapPage,
  // DemoMegaMenuPage,
  // DemoMultiLanguagePage,
  // DemoNavigationBarPage,
  // DemoOrganizationalChartPage,
  // DemoScrollbarPage,
  // DemoSnackbarPage,
  // DemoTextMaxLinePage,
  // DemoUploadPage,
  // DemoMarkdownPage,
  // JobsPage,
  // SponsorsPage,
  // EmployersPage,
  // StudentsPage,
  // SeasonPage,
  // ProgramsPage,
  // SchoolsPage,
  // RegistrationsPage,
  ChargesPage,
  // AdminLoginPage,
  PaymentsPage,
  // ChargeTypesPage,
  DashboardPage,
  StudentRegistrationPage,
  StudentInitialRegistrationPage,
  RegistrationContract,
  RegistrationDocument,
  LandingPage,
  JobOpeningPage
} from './elements';
import LogRedir from './LogRedir';
import RegGuard from '../auth/RegGuard';
import CompactDashboardLayout from '../layouts/dashboard/CompactDashboardLayout';
import RegProtect from '../auth/RegProtect';
import ContractProtect from '../auth/ContractProtect';


// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <RegGuard>
            <DashboardLayout />
          </RegGuard>
        </AuthGuard>
      ),
      children: [
        { element: <LogRedir />, index: true },
        // // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        // { path: 'app', element: <GeneralAppPage /> },
        // { path: 'ecommerce', element: <GeneralEcommercePage /> },
        // { path: 'analytics', element: <GeneralAnalyticsPage /> },
        // { path: 'banking', element: <GeneralBankingPage /> },
        // { path: 'booking', element: <GeneralBookingPage /> },
        // { path: 'file', element: <GeneralFilePage /> },
        // {
        //   path: 'e-commerce',
        //   children: [
        //     { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
        //     { path: 'shop', element: <EcommerceShopPage /> },
        //     { path: 'product/:name', element: <EcommerceProductDetailsPage /> },
        //     { path: 'list', element: <EcommerceProductListPage /> },
        //     { path: 'product/new', element: <EcommerceProductCreatePage /> },
        //     { path: 'product/:name/edit', element: <EcommerceProductEditPage /> },
        //     { path: 'checkout', element: <EcommerceCheckoutPage /> },
        //   ],
        // },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/account" replace />, index: true },
            // { path: 'profile', element: <UserProfilePage /> },
            // { path: 'cards', element: <UserCardsPage /> },
            // { path: 'list', element: <UserListPage /> },
            // { path: 'new', element: <UserCreatePage /> },
            // { path: ':name/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
            { path: 'registration', element: <StudentRegistrationPage /> },
          ],
        }, {
          path: 'students',
          children: [
            { element: <Navigate to="/dashboard/students/list" replace />, index: true },
            // { path: 'profile', element: <UserProfilePage /> },
            // { path: 'cards', element: <UserCardsPage /> },
            // { path: 'list', element: <StudentsPage /> },
            // { path: 'new', element: <UserCreatePage /> },
            // { path: ':name/edit', element: <UserEditPage /> },
            // { path: 'account', element: <UserAccountPage /> },
          ],
        }, 
        {
          path: 'transactions',
          children: [
            { element: <Navigate to="/dashboard/transactions/charges" replace />, index: true },
            { path: 'charges', element: <ChargesPage /> },
            { path: 'payments', element: <PaymentsPage /> },

          ],
        },
        { path: 'home', element: <DashboardPage /> },
        { path: 'document', element: <RegistrationDocument /> },
        { path: 'jobopenings', element: <JobOpeningPage /> },

      ],


    },

    {
      element: 
        <CompactDashboardLayout />,
      children: [
        { path: 'register', element: <RegProtect><StudentInitialRegistrationPage /></RegProtect> },
        { path: 'contract', element: <ContractProtect><RegistrationContract /> </ContractProtect> },
      ]
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <LandingPage />, index: true, path:'welcome' },
        // { element: <Navigate to="/components/extra/animate" replace />, index: true },
        // { element: <LogRedir />, index: true },
        // { element: <Navigate to="/dashboard/home" replace />, index: true },
        // { path: 'about-us', element: <AboutPage /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <FaqsPage /> },

        // Demo Components
        // {
        //   path: 'components',
        //   children: [
        //     { element: <ComponentsOverviewPage />, index: true },
        //     {
        //       path: 'foundation',
        //       children: [
        //         { element: <Navigate to="/components/foundation/colors" replace />, index: true },
        //         { path: 'colors', element: <FoundationColorsPage /> },
        //         { path: 'typography', element: <FoundationTypographyPage /> },
        //         { path: 'shadows', element: <FoundationShadowsPage /> },
        //         { path: 'grid', element: <FoundationGridPage /> },
        //         { path: 'icons', element: <FoundationIconsPage /> },
        //       ],
        //     },
        //     {
        //       path: 'mui',
        //       children: [
        //         { element: <Navigate to="/components/mui/accordion" replace />, index: true },
        //         { path: 'accordion', element: <MUIAccordionPage /> },
        //         { path: 'alert', element: <MUIAlertPage /> },
        //         { path: 'autocomplete', element: <MUIAutocompletePage /> },
        //         { path: 'avatar', element: <MUIAvatarPage /> },
        //         { path: 'badge', element: <MUIBadgePage /> },
        //         { path: 'breadcrumbs', element: <MUIBreadcrumbsPage /> },
        //         { path: 'buttons', element: <MUIButtonsPage /> },
        //         { path: 'checkbox', element: <MUICheckboxPage /> },
        //         { path: 'chip', element: <MUIChipPage /> },
        //         { path: 'data-grid', element: <MUIDataGridPage /> },
        //         { path: 'dialog', element: <MUIDialogPage /> },
        //         { path: 'list', element: <MUIListPage /> },
        //         { path: 'menu', element: <MUIMenuPage /> },
        //         { path: 'pagination', element: <MUIPaginationPage /> },
        //         { path: 'pickers', element: <MUIPickersPage /> },
        //         { path: 'popover', element: <MUIPopoverPage /> },
        //         { path: 'progress', element: <MUIProgressPage /> },
        //         { path: 'radio-button', element: <MUIRadioButtonsPage /> },
        //         { path: 'rating', element: <MUIRatingPage /> },
        //         { path: 'slider', element: <MUISliderPage /> },
        //         { path: 'stepper', element: <MUIStepperPage /> },
        //         { path: 'switch', element: <MUISwitchPage /> },
        //         { path: 'table', element: <MUITablePage /> },
        //         { path: 'tabs', element: <MUITabsPage /> },
        //         { path: 'textfield', element: <MUITextFieldPage /> },
        //         { path: 'timeline', element: <MUITimelinePage /> },
        //         { path: 'tooltip', element: <MUITooltipPage /> },
        //         { path: 'transfer-list', element: <MUITransferListPage /> },
        //         { path: 'tree-view', element: <MUITreesViewPage /> },
        //       ],
        //     },
        //     {
        //       path: 'extra',
        //       children: [
        //         { element: <Navigate to="/components/extra/animate" replace />, index: true },
        //         { path: 'animate', element: <DemoAnimatePage /> },
        //         { path: 'carousel', element: <DemoCarouselsPage /> },
        //         { path: 'chart', element: <DemoChartsPage /> },
        //         { path: 'copy-to-clipboard', element: <DemoCopyToClipboardPage /> },
        //         { path: 'editor', element: <DemoEditorPage /> },
        //         { path: 'form-validation', element: <DemoFormValidationPage /> },
        //         { path: 'image', element: <DemoImagePage /> },
        //         { path: 'label', element: <DemoLabelPage /> },
        //         { path: 'lightbox', element: <DemoLightboxPage /> },
        //         { path: 'map', element: <DemoMapPage /> },
        //         { path: 'mega-menu', element: <DemoMegaMenuPage /> },
        //         { path: 'multi-language', element: <DemoMultiLanguagePage /> },
        //         { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
        //         { path: 'organization-chart', element: <DemoOrganizationalChartPage /> },
        //         { path: 'scroll', element: <DemoScrollbarPage /> },
        //         { path: 'snackbar', element: <DemoSnackbarPage /> },
        //         { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
        //         { path: 'upload', element: <DemoUploadPage /> },
        //         { path: 'markdown', element: <DemoMarkdownPage /> },
        //       ],
        //     },
        //   ],
        // },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <LogRedir />, index: true, path: '/' },
        // { path: 'pricing', element: <PricingPage /> },
        // { path: 'payment', element: <PaymentPage /> },

      ],
    },
    {
      element: <CompactLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoonPage /> },
        // { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
