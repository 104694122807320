import { useState, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';


// @mui
import { Grid, Card, Stack, Typography, Paper } from '@mui/material';

import { getSysInfo } from '../api/sysApi';
import { richText } from '../utils/main';


// ----------------------------------------------------------------------

export default function Infography({ id }) {
    // Fetch Data
    const qData = useQuery({
        queryKey: ['sysInfo'], queryFn: async () => {
            const d = await getSysInfo(id);
            if (d.data) return d.data;
            return [];
        }, refetchOnWindowFocus: false,
    });


    // qData?.isError(e =>{
    //     window.location.href = '/'
    // })

    // const richText = (text) => {
    //     const regex = /\*(.*?)\*/g;
    //     return text.replace(regex, '<strong>$1</strong>');
    //   };

    return (
        <>
            {
                qData?.data?.map(i =>
                    <div key={i.id} >
                        <div style={{fontWeight:700}}>
                            {i.title}
                        </div>
                        
                        <div style={{fontWeight:500}} dangerouslySetInnerHTML={{ __html: richText(i.description) }} />
                       
                    </div>)
            }

        </>
    );
}
