import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import Login from '../pages/auth/LoginPage';
import { useAuthContext } from './useAuthContext';
import { PATH_AGREEMENT, PATH_REGISTRATION } from '../routes/paths';

// ----------------------------------------------------------------------

RegProtect.propTypes = {
  children: PropTypes.node,
};

export default function RegProtect({ children }) {
  const { isAuthenticated, isInitialized, user, initialize } = useAuthContext();

//   console.log(user, "GUARDY")
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  console.log(user, "USER")

  if(user === null) {
    return <Navigate to='/' />;
  }

  // Submitted registration so we will redir from reg page
  if ((user && user !== null && user?.registration !== null) && user?.registration?.submitted_on !== null) {
   
    console.log(pathname, requestedLocation)
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    

    // window.location.href= 'https://student.goitce.com';
    // return true;
    return <Navigate to='/' />;
  }
  

  if (requestedLocation && pathname !== requestedLocation) {
    // console.log('Reg redir')
    setRequestedLocation(null);
    // window.location.href= requestedLocation;
    // return true;  
      return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
