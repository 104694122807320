import React from 'react';
import { useQuery } from 'react-query';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';
import { getMessages } from '../api/utilApi';


// Define the marquee animation
const marqueeAnimation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export default function MarqueeTicker({ messages }) {

    const messagesQD = useQuery({
        queryKey:['tickerMessage'],
        queryFn: async () => { 
           const data = await getMessages();
           return data.data.filter(message => message.ticker === true);
        },
        refetchOnWindowFocus:true
    })

  return (
    <Box sx={{ overflow: 'hidden', whiteSpace: 'nowrap', width: '100%', padding: '10px' }}>
      <Box
        sx={{
          display: 'inline-block',
          animation: `${marqueeAnimation} 30s linear infinite`,
        }}
      >
        {messagesQD?.data?.map((message, index) => (
          <Box key={index} sx={{ display: 'inline-block', marginRight: '50px' }}>
            <Typography variant="h6" component="span" sx={{ fontWeight: 'bold', marginRight: '10px', color:"red" }}>
              {message.title}:
            </Typography>
            <Typography variant="body1" component="span" sx={{ marginRight: '10px', color:'#333333' }}>
              {message.message}
            </Typography>
            <Typography variant="caption" component="span" sx={{color:'#666'}}>
              {new Date(message.created_at).toLocaleDateString()}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
