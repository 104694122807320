import React, { createContext, useState, useEffect } from 'react';

// Create a Context
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [languageSettings, setLanguageSettings] = useState(null);

  useEffect(() => {
    const loadLanguageSettings = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_LANG || 'https://goitce.com/ext_assets/app_config/student.json');
        const data = await response.json();
        setLanguageSettings(data);
      } catch (error) {
        console.error('Failed to load language settings:', error);
      }
    };

    loadLanguageSettings();
  }, []);

  if (!languageSettings) {
    return <div>Loading...</div>; // You can also show a loader or fallback UI
  }

  return (
    <LanguageContext.Provider value={languageSettings}>
      {children}
    </LanguageContext.Provider>
  );
};
