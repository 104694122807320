import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { LanguageContext } from '../LanguageContext';
// components
import LoadingScreen from '../components/loading-screen';
//
import Login from '../pages/auth/LoginPage';
import { useAuthContext } from './useAuthContext';
import { PATH_AGREEMENT, PATH_PAGE, PATH_REGISTRATION } from '../routes/paths';

// ----------------------------------------------------------------------

RegGuard.propTypes = {
  children: PropTypes.node,
};

export default function RegGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuthContext();
  const { language: { alerts: { activationMessage } } } = useContext(LanguageContext);

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [alertDisplayed, setAlertDisplayed] = useState(false); // New state

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (user.registration === null || user.registration?.submitted_on === null) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    window.location.href = PATH_REGISTRATION.root;
    return true;
  }

  if (user.registration && user.registration.active !== 'yes') {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    window.localStorage.removeItem('accessToken');

    // Check if the alert has been displayed already
    if (!alertDisplayed) {
      alert(activationMessage || "Your account is awaiting activation. Please contact our office if you have paid your registration fee for more than 3 business days.");
      setAlertDisplayed(true); // Set alert as displayed
    }

    return <Navigate to={PATH_PAGE.welcome} />;
  }

  if (user.registration && user.registration.agreed_on === null && user.registration.active === 'yes') {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={PATH_AGREEMENT.root} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
/*
export default function RegGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuthContext();
  const { language: {alerts: { activationMessage }} }  = useContext(LanguageContext);

  // console.log(user, "regguard")
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

    // Non submitted registration
  if (user.registration === null  || user.registration?.submitted_on === null) {
   
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
     window.location.href = PATH_REGISTRATION.root;
    return true;
    // return <Navigate to={PATH_REGISTRATION.root} />;
  }

  // Account not active
  if (user.registration  && user.registration.active !== 'yes') {
   
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    window.localStorage.removeItem('accessToken');
    alert(activationMessage || "Your account is awaiting activation. Please contact our office if you have paid your registration fee for more than 3 business days.");
    return <Navigate to={PATH_PAGE.welcome} />;
  }

  
  // Submitted registration but not agreed
  // if (user.registration  &&  user.registration.agreed_on === null) {
  if (user.registration  &&  user.registration.agreed_on === null && user.registration.active === 'yes') {
   
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    // window.location.href = PATH_AGREEMENT.root;
    // return true;
    return <Navigate to={PATH_AGREEMENT.root} />;
  }



  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    // window.location.href = requestedLocation;
    // return true;
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
*/