import { WindowsFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
 

  useEffect(() => {
    const handleErrors = (error, errorInfo) => {
      // You can perform custom error handling here
      console.error('Error Boundary:', error);
      console.error('Error Boundary Info:', errorInfo);
      setHasError(true);
    };

    // Attach the error handler
    window.addEventListener('error', handleErrors);
    return () => {
      // Cleanup: Remove the error handler
      window.removeEventListener('error', handleErrors);
    };
  }, []);

  if (hasError) {
    // You can render a fallback UI for the error state
    setTimeout(()=> {window.location.href = '/500'}, 1000);
    // return <div>Error occurred. Please try again.</div>;
    // window.location.href = '/500'
  }

  return children;
};

export default ErrorBoundary;
