// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_REGISTRATION = '/register';
const ROOTS_AGREEMENT = '/contract';

// ----------------------------------------------------------------------

export const PATH_AGREEMENT = {
  root: ROOTS_AGREEMENT
}
export const PATH_REGISTRATION = {
  root: ROOTS_REGISTRATION
}

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  welcome: '/welcome',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  // kanban: path(ROOTS_DASHBOARD, '/kanban'),
  // calendar: path(ROOTS_DASHBOARD, '/calendar'),
  // fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  // permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  // blank: path(ROOTS_DASHBOARD, '/blank'),
  // general: {
  //   app: path(ROOTS_DASHBOARD, '/app'),
  //   ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
  //   analytics: path(ROOTS_DASHBOARD, '/analytics'),
  //   banking: path(ROOTS_DASHBOARD, '/banking'),
  //   booking: path(ROOTS_DASHBOARD, '/booking'),
  //   file: path(ROOTS_DASHBOARD, '/file'),
  // },
  // mail: {
  //   root: path(ROOTS_DASHBOARD, '/mail'),
  //   all: path(ROOTS_DASHBOARD, '/mail/all'),
  // },
  // chat: {
  //   root: path(ROOTS_DASHBOARD, '/chat'),
  //   new: path(ROOTS_DASHBOARD, '/chat/new'),
  //   view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  // },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    // new: path(ROOTS_DASHBOARD, '/user/new'),
    // list: path(ROOTS_DASHBOARD, '/user/list'),
    // cards: path(ROOTS_DASHBOARD, '/user/cards'),
    // profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    registration: path(ROOTS_DASHBOARD, '/user/registration'),
    // edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    // demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  students: {
    root: path(ROOTS_DASHBOARD, '/students'),
    // new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/students/list'),
    // cards: path(ROOTS_DASHBOARD, '/user/cards'),
    // profile: path(ROOTS_DASHBOARD, '/user/profile'),
    // account: path(ROOTS_DASHBOARD, '/user/account'),
    // edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    // demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
   registrations: {
    root: path(ROOTS_DASHBOARD, '/registrations'),
    // new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/registrations/list'),
    // cards: path(ROOTS_DASHBOARD, '/user/cards'),
    // profile: path(ROOTS_DASHBOARD, '/user/profile'),
    // account: path(ROOTS_DASHBOARD, '/user/account'),
    // edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    // demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },

  transactions: {
    root: path(ROOTS_DASHBOARD, '/transactions'),
    charges: path(ROOTS_DASHBOARD, '/transactions/charges'),
    payments: path(ROOTS_DASHBOARD, '/transactions/payments'),
  },
  dashhome: {
    root: path(ROOTS_DASHBOARD, '/home')
  },
  document: {
    root: path(ROOTS_DASHBOARD, '/document')
  },
  jobopenings: {
    root: path(ROOTS_DASHBOARD, '/jobopenings')
  },
  



  jobs:{
    root: path(ROOTS_DASHBOARD, '/jobs')
  },
  programs:{
    root: path(ROOTS_DASHBOARD, '/programs')
  },
  sponsors:{
    root: path(ROOTS_DASHBOARD, '/sponsors')
  }
  ,employers:{
    root: path(ROOTS_DASHBOARD, '/employers')
  },
  season:{
    root: path(ROOTS_DASHBOARD, '/season')
  },
  schools:{
    root: path(ROOTS_DASHBOARD, '/schools')
  },
  chargetypes:{
    root: path(ROOTS_DASHBOARD, '/chargetypes')
  },

  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
