import axios from "axios";
import { HOST_API_KEY } from "../config-global";

const utilApi = axios.create({
    // baseURL: 'http://localhost:3009/api'
    baseURL: `${HOST_API_KEY}util`,

});

export const getCountries = async() => {
    const response = await utilApi.get("/countries")
    return response.data
}

export const getMessages = async() => {
    const response = await utilApi.get("/messages")
    return response.data
}

export default utilApi;