import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY, timeout:60000 });

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

// axiosInstance.interceptors.response.use(
//   (response) => {
//     console.log(response,"PJ")
//     return response
//   },
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       // Handle 401 Unauthorized error
//       // Perform necessary actions such as redirecting to a login page or showing an error message
//       console.log('Unauthorized: Please log in.');
//     }
//     return Promise.reject((error.response && error.response.data) || 'Something went wrong');
//   }
// );


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.log(error, "inter--")
    // Handle the error here or perform any desired actions
    if (error.response) {
      console.log('API Error:', error.response.status);
    } else if (error.request) {
      console.log('API Error: Request failed');
    } else {
      console.log('API Error:', error.message);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
