import { Helmet } from 'react-helmet-async';
// sections
import Login from '../../sections/auth/Login';
import { APP_SETTINGS } from '../../app-settings';
// import Login from '../../sections/auth/LoginAuth0';

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
     <Helmet>
        <title> Login | {APP_SETTINGS.TITLE}</title>
      </Helmet>

      <Login />
    </>
  );
}
