import PropTypes from 'prop-types';
// @mui
import { Grid, Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent, StyledContent2 } from './styles';
import landing from "../../assets/illustrations_/login_admin.svg"
import Infography from '../../components_/Infography';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title, id }) {
  return (
    <><StyledRoot>
      <Logo
        sx={{
          zIndex: 9,
          position: 'absolute',
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      />

      <StyledSection>
        <Typography variant="h3" sx={{ mb: 1, maxWidth: 480, textAlign: 'center' }}>
          {title || 'Hi, Welcome back'}
        </Typography>


        <img alt="Login" src={landing} style={{ maxWidth: 200 }} />



        <StyledSectionBg />
      </StyledSection>


      {id === 'login' ? <StyledContent>

        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
        : <StyledContent2>

          <Stack sx={{ width: 1 }}> {children} </Stack>
        </StyledContent2>
      }


    </StyledRoot>

    </>

  );
}
