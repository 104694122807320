import ReactDOM from 'react-dom/client';
//
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { LanguageProvider } from './LanguageContext';

// ----------------------------------------------------------------------
import App from './App';

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <QueryClientProvider client={queryClient}>
        <LanguageProvider>  {/* Wrap your app here */}
            <App />
        </LanguageProvider>
        {/* <ReactQueryDevtools initialIsOpen /> */}
    </QueryClientProvider>


);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
