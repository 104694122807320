import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import Login from '../pages/auth/LoginPage';
import { useAuthContext } from './useAuthContext';
import { PATH_AGREEMENT, PATH_REGISTRATION } from '../routes/paths';

// ----------------------------------------------------------------------

ContractProtect.propTypes = {
  children: PropTypes.node,
};

export default function ContractProtect({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuthContext();

  // console.log(user, "GUARDY")
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

// Go finish registration
if (user?.registration.submitted_on === null) {
   
  if (pathname !== requestedLocation) {
    setRequestedLocation(pathname);
  }
  return <Navigate to={PATH_REGISTRATION.root} />;
}


  if (user?.registration.agreed_on !== null) {
   
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to='/' />;
  }
  

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
