import React from 'react'
import { PATH_AFTER_LOGIN2 } from '../config-global';
import { useAuthContext } from '../auth/useAuthContext';
import { PATH_AGREEMENT, PATH_AUTH, PATH_DASHBOARD, PATH_REGISTRATION} from './paths';
import LoadingScreen from '../components/loading-screen/LoadingScreen';


export default function LogRedir() {
    const { user } = useAuthContext();
// console.log(PATH_REGISTRATION.root, PATH_DASHBOARD.dashhome)

    if(!user?.uuid) {
        window.location.href = PATH_AUTH.login 
        return false;
    }

    // Check if account verified and active
    if(user?.email_verified === false) {
        // window.location.href = PATH_AUTH.register;
    }


    // Check if registration exists
    if(user.registration === undefined || user.registration === null) {
       window.location.href = PATH_REGISTRATION.root;
       return false;
    }

    // Check if registration completed
    if(user.registration.submitted_on === null) {
        window.location.href = PATH_REGISTRATION.root;
      return false;
    }

    // Check if account notactivated after payment --- AUG-08-2023
    if(user.registration && user.registration?.active !== 'yes') {
        alert("Your account is awaiting activation... Please contact our office.");
        window.localStorage.removeItem('accessToken');
        window.location.href = "tel: 1876 631 9852";
        return false;
     } 
 

    // Check if Agreement signed
     if(user.registration !== null && user.registration?.agreed_on === null) {
        window.location.href = PATH_AGREEMENT.root;
        return false;
     } 
 
    // Check if registration active [YES]
    if(user.registration && user.registration?.active === 'yes') {
       window.location.href = PATH_DASHBOARD.dashhome.root;
       return false;
    } 


    // window.location.href = PATH_DASHBOARD.dashhome.root;

        // alert("Account not activated... but will proceed to dashboard for testing purposes");
        // window.location.href = '/auth/login';
     
  
        
 

//    React.useEffect(() =>{
//         window.location.href = PATH_AFTER_LOGIN2
//     })

    return (
        <LoadingScreen/>
       
    )
 
}
