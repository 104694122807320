// routes
import { PATH_DASHBOARD } from './routes/paths';

// APP 
// ----------------------------------------------------------------------

export const APP_SETTINGS = {
  TITLE: process.env.REACT_APP_TITLE || "ITCE - DEV"

  
};
