import { useQuery } from 'react-query';
// @mui
import { Stack, Button, Typography, Box } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
import { APP_SETTINGS } from '../../../app-settings';

// locales
import { useLocales } from '../../../locales';
// routes
import { PATH_DOCS } from '../../../routes/paths';
import { getBuild } from '../../../utils/main';


// ----------------------------------------------------------------------

export default function NavDocs() {
  const { user } = useAuthContext();
  const { translate } = useLocales();

  const buildQD = useQuery({queryKey:['build'], queryFn: getBuild});

  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        display: 'block',
        textAlign: 'center',
      }}
    >
      {/* <Box component="img" src="/assets/illustrations/illustration_docs.svg" /> */}

      {/* <div>
        <Typography gutterBottom variant="subtitle1">
          {`${translate('docs.hi')}, ${user?.displayName}`}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          {`${translate('docs.description')}`}
        </Typography>
      </div> */}
      <div>
        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          &copy; {new Date().getFullYear()} {APP_SETTINGS.TITLE} All rights reserved.
        </Typography>
      </div> 
      <div>
        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line', fontSize:'0.7em' }}>
      Build: {buildQD?.data?.build}

      
        </Typography>
      </div>
      {/* <Button href={PATH_DOCS.root} target="_blank" rel="noopener" variant="contained">
        {`${translate('docs.documentation')}`}
      </Button> */}
    </Stack>
  );
}
