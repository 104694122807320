export const checkObjEquality = (obj1, obj2) => {
  const obj1Keys = Object.keys(obj1);
  return obj1Keys.every((key) => obj1[key] === obj2[key]);
}

export const convertToNaturalLanguage = (str) => {
  // Replace underscores with spaces
  const withSpaces = str.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  const capitalized = withSpaces.replace(/\b\w/g, (match) => match.toUpperCase());

  return capitalized.toUpperCase();
}

export const caps = (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()

export const richText = (text) => {
  const regex = /\*(.*?)\*/g;
  return text.replace(regex, '<strong>$1</strong>');
};

export const getLabelById = (id, objArr) => {
  const foundObject = objArr.find(obj => obj.id === id);
  return foundObject ? foundObject.label : null;
}



export const getBuild = async() => {
  try {
    const b = await fetch("../../metadata.json")
      .then((response) => response.json())
      .then((data) =>  data)
      .catch((err) =>  err);
    return b;
  } catch (error) {
    console.log(error);
    return null;
  }
}


export const getTimeZone = async() =>  Intl.DateTimeFormat().resolvedOptions().timeZone


// module.exports = {
//   getBuild,
//   caps,
//   richText,
//   checkObjEquality,
//   convertToNaturalLanguage,
//   getLabelById
// }